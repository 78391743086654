<!-- (RD)PMB-7504 supplier order details UI change -->
<div class="overlay-body" [formGroup]="editchargesForm">
    <form autocomplete="off" [formGroup]="editchargesForm" class="custom-body">
        <div formArrayName="editChargesArray">
            <mat-accordion class="expansionPanel" [togglePosition]="'before'">
                <mat-expansion-panel [formGroupName]="i" class="customisedExpansion-panel"
                    *ngFor="let order of editchargesForm.get('editChargesArray')['controls']; let i=index">
                    <mat-expansion-panel-header class="chargeListHeader">
                        <mat-panel-description class="chargeDescription">
                            <div class="details name">
                                <span>{{order.controls.chargeName.value}}</span> /
                                <span>{{order.controls.applyByCode.value}}</span>
                            </div>
                            <div class="details company">
                                <span>{{order.controls.companyName.value}}</span>
                            </div>
                            <div class="details cost">
                                <span class="symbol">{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol'
                                    }}</span><span>{{order.controls.chargeAmt.value}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <edit-charges-overlay [data]="data" [order]="order" [parent]="editchargesForm"
                        [index]=i></edit-charges-overlay>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </form>
</div>
<div mat-dialog-actions class="overlay-footer">
    <div class="left-section">
        <button id="cancel_CopyOverlay" [disabled]="dialogData?.isSplitPoFlagActive"
            (click)="add_new()"><span>{{'CHARGE.LABEL.ADD_CHARGE'|translate}}</span></button>
    </div>
    <div class="right-section">
        <button id="submit_Expand"
            [disabled]="poStatus || editchargesForm.invalid || chargeFlag || dialogData?.isSplitPoFlagActive || isChargeMappedFlag"
            (click)="submitCharges();" *ngIf="!auth?.user?.info?.internalAdmin && permissions"><span>{{'BUTTON.SAVE' |
                translate}}</span>
        </button>
    </div>
</div>