import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from '../../../../environments/config.url';

@Injectable({
    providedIn: 'root'
})
export class ChargesService {

    private headers = new HttpHeaders();

    constructor( public http: HttpClient, @Inject('env') private env) {
        this.headers.set('Content-Type', 'application/json');
    }

    getCharges(chargeOn): Observable<any> {
        let url = this.env.BASE_API_URL + URLS.GetChargesListAPI + "?&chargeOn=" + chargeOn;
        // return this.$http.baseUrlContext.url("/admin/api/charge/list").get()
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            })
        );
        //.pipe(map(res => this.extractData(res)));
    }


    getChargeSetting(settingType): Observable<any> {
        // return this.$http.baseUrlContext.url("/order/po/charge/setting/" + settingType).get()
        let url = this.env.BASE_API_URL + URLS.GetChargeSettingsAPI + settingType;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            })
        );

        //.pipe(map(res => this.extractData(res)));
    }

    addUpdatePOLinesCharges(data): Observable<any> {
        // return this.$http.baseUrlContext.url("/order/po/line/charge").post(data)
        let url = this.env.BASE_API_URL + URLS.AddUpdatePOLineChargesAPI;
        return this.http.post<any>(url, data, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            })
        );

        //.pipe(map(res => this.extractData(res)));
    }

    addUpdatePOHeaderCharges(data): Observable<any> {
        // return this.$http.baseUrlContext.url("/order/po/charge").post(data)
        let url = this.env.BASE_API_URL + URLS.AddUpdatePOHeaderChargesAPI;
        return this.http.post<any>(url, data, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            })
        );
        //.pipe(map(res => this.extractData(res)));
    }

    addUpdateInvoiceHeaderCharges(data): Observable<any> {
        let url = this.env.BASE_API_URL + URLS.AddUpdateInvoiceHeaderChargesAPI;
        return this.http.post<any>(url, data, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            })
        );
    }

    addUpdateInvoiceLinesCharges(data): Observable<any> {
        let url = this.env.BASE_API_URL + URLS.AddUpdateInvoiceLineChargesAPI;
        return this.http.post<any>(url, data, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            })
        );

        //.pipe(map(res => this.extractData(res)));
    }

    extractData(response) {
        return response.body;
    }

    //(PM)PMB-7631 Add ability to default charge apply by and prorate by settings for a buying company
    getAppModuleAccess(data): Observable<any> {
        let url = this.env.BASE_API_URL + URLS.BRRules;
        return this.http.post<any>(url, data, { headers: this.headers }).pipe(
          map((res) => {
            return res;
          }));
      }

      //(PM) PMB-10447 PO charges should be visible on Invoices and editable
      getMappedChargesForSup(chargeId?, partnerOrganizationId?): Observable<any> {
        let url = this.env.BASE_API_URL + URLS.GetMappedChargesListAPI + chargeId + '/' + partnerOrganizationId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

}


// Add this code for animation (Date:16-01-2023)
import { style, animate, transition } from '@angular/animations';
 
export function fadeIn(){
    return [
        transition(':enter', [
            style({opacity: 0}),
            animate('400ms ease-in', style({opacity: 1}))
          ])
      ];
}
 
export function slideInOut(){
    return [
        transition(':enter', [
            style({transform: 'translateY(-100%)'}),
            animate('200ms ease-in', style({transform: 'translateY(0%)'}))
          ]),
          transition(':leave', [
            animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
          ])
      ];
}

// End here