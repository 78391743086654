<app-toolbar [appName]="true" (appHomesClick)="eventclick($event)">
  <div logo><img src="{{IMAGES.PROCURANT_LOGO}}"></div>
  <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
  <div product-name>
    Supplier
  </div>
  <span app-notifs class="header-icon icon-bell" [ngClass]="[headerFlag? 'pendingNotifications' : '']"
    title="{{'BUTTON.REMINDERS'| translate}}" [matMenuTriggerFor]="filterMenu" (click)="getRemindersList()"></span>
  <div>
    <mat-menu class="filterBox dashboardFilter" #filterMenu="matMenu">
      <div fxLayout="column" fxLayoutAlign="start start" (click)="$event.stopPropagation();">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="headerWithIcon mb-20">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="icon-bell mr-15"></span>
            <h3 class="mr-10">{{'REMINDER.LABEL.ACTIVE_REMINDERS'| translate}}</h3>
          </div>
          <mat-slide-toggle [attr.data-target]="count" [checked]="dismissed===true ? true:false"
            (change)="onChange($event,0,'all')" [disabled]="auth?.user?.info?.internalAdmin"
            *ngIf='!displayItem'></mat-slide-toggle>
        </div>

        <div style="min-width: auto;max-height: 200px; overflow-y: auto;overflow-x: hidden;width: 100%;;" #scrollMe
          *ngIf='!displayItem && !displayEditMode && !noDataMessage'>
          <div *ngFor="let reminder of remindersList" class="fs-14 customItems" fxLayout="row"
            fxLayoutAlign="start stretch">
            <div style="width: 100%;padding: 10px 0px 10px 10px;"
              [ngStyle]="{'background-color': reminder.flag!=0 ? '#E8EAED': '#fff'}">
              <div class="reminderText" [ngClass]="!displayEditMode ? '':'reminderText mr-0 ml-0'">
                <div class="viewMode" *ngIf='!displayEditMode'>
                  <div>{{reminder.description}}</div>
                  <span class="statusTag greyTag">{{reminder.date}}</span>
                  <mat-slide-toggle [disabled]="auth?.user?.info?.internalAdmin"
                    [checked]="reminder.dismissed===false ? true:false"
                    (change)="onChange($event,reminder.id)"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" *ngIf="noDataMessage && !displayItem">
        {{noDataMessage}}
      </div>
    </mat-menu>
  </div>
  <!-- <span app-notifs class="header-icon icon-chat_message"></span> -->
  <!-- <span userimage><img [src]="(user?.info?.profileimagelink)?user.info.profileimagelink:IMAGES.DEFAULTPROFILE" /></span> -->
  <span userimage>
    <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
    <div *ngIf="!auth?.thumbnil" class="img-avtar">
      <span>{{avtarName}}</span>
    </div>
  </span>
  <!-- <span username matTooltip="{{user?.info?.firstName}} {{user?.info?.lastName}}">
    {{user?.info?.firstName}} {{user?.info?.lastName}}
  </span> -->

  <!-- single column -->
  <!-- <div username class="user-profile">
    <div>
      <img [src]="auth?.thumbnil ? auth?.thumbnil : 'assets/images/profile.png'" router="UserProfileModule" alt=""
        title="" class="img">
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div> -->

  <!-- double column -->
  <div username class="double-user-profile">
    <div>
      <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
      <div *ngIf="!auth?.thumbnil" class="img-avtar">
        <span>{{avtarName}}</span>
      </div>
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div>

  <div menu-options>

    <!-- <span username matTooltip="{{user?.info?.firstName}} {{user?.info?.lastName}}">
      {{user?.info?.firstName}} {{user?.info?.lastName}}
    </span> -->
    <button *ngIf="!auth?.user?.info?.internalAdmin" class="logoutBtn" (click)="onNavigateSupplierResources()"
      mat-menu-item>
      {{ 'BUTTON.SUPPLIER_RESOURCES' | translate }}
    </button>
    <button class="logoutBtn" mat-menu-item (click)="logout()">
      {{ 'BUTTON.LOGOUT' | translate }}
    </button>
    <button *ngIf="auth?.user?.info?.internalAdmin" mat-menu-item (click)="returnToInternalAdmin()">
      {{ 'BUTTON.RETURN_INTERNAL_ADMIN' | translate }}
    </button>
  </div>
</app-toolbar>